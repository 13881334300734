import { createApp } from 'vue'
import App from './App.vue'
import VueWechatTitle from 'vue-wechat-title'
import axios from '@/Tool/Api';
import router from './routes';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import InfiniteScroll from "element-plus";

const app = createApp(App);
app.use(router)
app.use(VueWechatTitle)
app.use(Antd)
app.use(InfiniteScroll)
app.mount('#app');
app.config.globalProperties.$axios=axios