import { createRouter, createWebHistory } from 'vue-router';
const home = () => import('./Pages/home.vue');
const houseDetail = () => import('./Pages/houseDetail.vue');
const My = () => import('./Pages/My/My.vue');
const collect = () => import('./Pages/My/collect.vue');
const BrowHistory = () => import('./Pages/My/BrowHistory.vue');
const OrderList = () => import('./Pages/Order/OrderList.vue');
const OrderDetail = () => import('./Pages/Order/OrderDetail.vue');
const Chat = () => import('./Pages/Chat/Chat.vue');
const GiveServicePrompt = () => import('./Pages/Order/GiveService/GiveServicePrompt.vue');
const RealEstateOrder = () => import('./Pages/Order/GiveService/RealEstateOrder.vue');
const Notify = () => import('./Pages/Notify/Notify.vue');
const PayVip = () => import('./Pages/Order/Vip/PayVip.vue');
const More = () => import('./Pages/Order/Vip/More.vue');
const feedback = () => import('./Pages/My/Feedback/feedback.vue');
const feedbackOk = () => import('./Pages/My/Feedback/feedbackOk.vue');
const Report = () => import('./Pages/My/Report/Report.vue');
const ReportOk = () => import('./Pages/My/Report/ReportOk.vue');
const agreement_jzqg = () => import('./Pages/Agreement/agreement_jzqg.vue');
const agreement_qygf = () => import('./Pages/Agreement/agreement_qygf.vue');
const agreement_wts = () => import('./Pages/Agreement/agreement_wts.vue');
const agreement_deposit = () => import('./Pages/Agreement/agreement_deposit.vue');
const agreement_jzzy = () => import('./Pages/Agreement/agreement_jzzy.vue');
const agreement_vip = () => import('./Pages/Agreement/agreement_vip.vue');
const SupplementaryFees = () => import('./Pages/Order/SupplementaryFees.vue');
const TradingHouse=()=>import('./Pages/Order/TradingHouses.vue')
const ReleaseHouse=()=>import('./Pages/Order/ReleaseHouse.vue')
const Login=()=>import('./Pages/login.vue')
const realName=()=>import('./Pages/realName.vue')
// const AddressMap=()=>import('./Pages/Order/AddressMap.vue')

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            component: Login,
            name: 'login',
            meta: {
                title: '登录',
            }
        },
        {
            path: '/My/realName',
            component: realName,
            name: 'realName',
            meta: {
                title: '实名认证',
            }
        },
        {
            path: '/',
            component: home,
            name: 'home',
            meta: {
                title: '二手房'
            }
        },
        {
            path: '/houseDetail/:id',
            component: houseDetail,
            name: 'houseDetail',
            meta: {
                title: '房源详情',
                keep:false
            }
        },{
            path: '/My',
            component: My,
            name: 'My',
            meta: {
                title: '我的',
                keep:false
            }
        },{
            path: '/Chat',
            component: Chat,
            name: 'Chat',
            meta: {
                title: '直聊',
            }
        },
        {
            path: '/Notify',
            component: Notify,
            name: 'Notify',
            meta: {
                title: '消息',
            }
        },{
            path: '/My/collect',
            component: collect,
            name: 'collect',
            meta: {
                title: '我的收藏',
                keep:false
            }
        },{
            path: '/My/BrowHistory',
            component: BrowHistory,
            name: 'BrowHistory',
            meta: {
                title: '浏览记录',
                keep:false
            }
        },{
            path: '/Order/OrderList',
            component: OrderList,
            name: 'OrderList',
            meta: {
                title: '我的交易',
                keep:false
            }
        },{
            path: '/Order/OrderDetail/:id',
            component: OrderDetail,
            name: 'OrderDetail',
            meta: {
                title: '订单详情',
                keep:false
            }
        },{
            path: '/Order/GiveService/GiveServicePrompt/:id',
            component: GiveServicePrompt,
            name: 'GiveServicePrompt',
            meta: {
                title: '服务',
                keep:false
            }
        },{
            path: '/Order/GiveService/RealEstateOrder/:type/:title',
            component: RealEstateOrder,
            name: 'RealEstateOrder',
            meta: {
                title: '服务',
                keep:false
            }
        },{
            path: '/Order/Vip/PayVip',
            component: PayVip,
            name: 'PayVip',
            meta: {
                title: '购买星钻礼包',
                keep:false
            }
        },{
            path: '/Order/Vip/More',
            component: More,
            name: 'More',
            meta: {
                title: '更多权益',
                keep:false
            }
        },{
            path: '/Feedback/feedback',
            component: feedback,
            name: 'feedback',
            meta: {
                title: '问题反馈',
                keep:false
            }
        },{
            path: '/Feedback/feedbackOk',
            component: feedbackOk,
            name: 'feedbackOk',
            meta: {
                title: '问题反馈',
                keep:false
            }
        },{
            path: '/Report/Report/:id',
            component: Report,
            name: 'Report',
            meta: {
                title: '举报',
                keep:false
            }
        },{
            path: '/Report/ReportOk',
            component: ReportOk,
            name: 'ReportOk',
            meta: {
                title: '举报',
                keep:false
            }
        },{
            path: '/Agreement/agreement_jzqg',
            component: agreement_jzqg,
            name: 'agreement_jzqg',
            meta: {
                title: '精准求购服务须知',
                keep:false
            }
        },{
            path: '/Agreement/agreement_qygf',
            component: agreement_qygf,
            name: 'agreement_qygf',
            meta: {
                title: '权证委托服务须知',
                keep:false
            }
        },{
            path: '/Agreement/agreement_wts',
            component: agreement_wts,
            name: 'agreement_wts',
            meta: {
                title: '房屋托管授权委托书',
                keep:false
            }
        },{
            path: '/Agreement/agreement_deposit',
            component: agreement_deposit,
            name: 'agreement_deposit',
            meta: {
                title: '托管服务须知',
                keep:false
            }
        },{
            path: '/Agreement/agreement_jzzy',
            component: agreement_jzzy,
            name: 'agreement_jzzy',
            meta: {
                title: '安心陪购服务须知',
                keep:false
            }
        },{
            path: '/Agreement/agreement_vip',
            component: agreement_vip,
            name: 'agreement_vip',
            meta: {
                title: '星钻礼包购买须知',
                keep:false
            }
        },{
            path: '/Order/SupplementaryFees/:id',
            component: SupplementaryFees,
            name: 'SupplementaryFees',
            meta: {
                title: '补缴费用',
                keep:false
            }
        },{
            path:'/Order/TradingHouse',
            component:TradingHouse,
            name:'TradingHouse',
            meta:{
                title:'房屋交易',
                keep:false
            }
        },{
            path:'/Order/ReleaseHouse',
            component:ReleaseHouse,
            name:'ReleaseHouse',
            meta:{
                title:'录入房源',
                keep:false
            }
        }
        // ,{
        //     path:'/Order/AddressMap',
        //     component:AddressMap,
        //     name:'AddressMap',
        //     meta:{
        //         title:'选择地点',
        //         keep:false
        //     }
        // }

        
    ]
})

export default router;