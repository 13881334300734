import { reactive } from 'vue'

export const store = reactive({
    wss_url:'wss://house.home.suixichanquan.com:5116/api/ChatHandler',
    // UserInfo:{
    //     head_img:"https://suixifile.oss-cn-beijing.aliyuncs.com/finance_xcx/UserHeadImg/headimg.png",
    //     name:'用户@15198277360',
    //     phone:'15198277360',
    //     real_name:'蒲桂林',
    //     real_status:1,
    //     register_time:'2024/3/28 16:00:30',
    //     smyUid:'20771814',
    //     uid:410,
    //     vipEndTime:null,
    //     vipId:null
    // },
    UserInfo:null,
    CarouselIndex:0,
    jzqg_house:'',
    cachedViews:['home'],
    newHouseAddress:{

    },
    //临时登录信息
    temporaryData:{
        
    },
    mutations:{
        // 添加缓存
        ADD_CACHED_VIEW: (name) => {
            if (store.cachedViews.includes(name)) {
                return
            }
            store.cachedViews.push(name)
        },
        // 移除缓存
        DEL_CACHED_VIEW: (name) => {
            const index = store.cachedViews.indexOf(name)
            index > -1 && store.cachedViews.splice(index, 1)
        }
    }
})
