import axios from "axios";
// axios.defaults.withCredentials = true
const asx=axios.create({
    timeout:10000
})
const smyUrl = 'https://tfsmy.chengdu.gov.cn/';
// const suixiUrl = 'http://text-smy-home.suixichanquan.com:7702/';
const suixiUrl = 'https://smy-home.suixichanquan.com:7701/';
const client_id = '526b4ed6-a792-474d-93a6-a23108f64fe4';
function Post(url,data){
    return new Promise((resolve, reject)=>{
        asx({
            url:'/smyapi/'+url,
            data:data,
            method:'post',
            headers:{'Content-Type':'application/json'}
        }).then(res=>{
            if(res.status==200){
                if(res.data.status == -404&&(url.includes('GetSmyUserInfo')||url.includes('SmyHouseListQuery'))){
                    goSmyUrl();
                }
                resolve(res.data)
            }else{
                reject(res.data)
            }
        })
    })
}
function Get(url,data){
    return new Promise((resolve, reject)=>{
        asx({
            url:'/smyapi/'+url,
            params:data,
            method:'get',
            headers:{'Content-Type':'application/json'}
        }).then(res=>{
            if(res.status==200){
                if(res.data.status == -404&&(url.includes('GetSmyUserInfo')||url.includes('SmyHouseListQuery'))){
                    goSmyUrl();
                }
                resolve(res.data)
            }else{
                reject(res.data)
            }
        })
    })
}
function goSmyUrl(){
    console.log('1秒后执行重新登录');
    setTimeout(() => {
      
        // window.sessionStorage.clear('SmyUserInfo');
        // window.location.href = smyUrl+'api/oauth/authorize?client_id='+client_id+'&response_type=code&redirect_uri='+suixiUrl+'&scope=read';
    }, 1000);
}
function xcxPost(url,data){
    return new Promise((resolve, reject)=>{
        asx({
            url:'/smyapi/'+url,
            data:data,
            method:'post',
            headers:{'Content-Type':'application/json'}
        }).then(res=>{
            if(res.status==200){
                if(res.data.status == -404&&(url.includes('GetSmyUserInfo')||url.includes('SmyHouseListQuery'))){
                    goSmyUrl();
                }
                resolve(res.data)
            }else{
                reject(res.data)
            }
        })
    })
}
function postByForm(url,data){
    return new Promise((resolve, reject)=>{
        asx({
            url:'/smyapi/'+url,
            data:data,
            method:'post',
            headers:{'Content-Type':'application/x-www-form-urlencoded'}
        }).then(res=>{
            if(res.status==200){
                if(res.data.status == -404&&(url.includes('GetSmyUserInfo')||url.includes('SmyHouseListQuery'))){
                    goSmyUrl();
                }
                resolve(res.data)
            }else{
                reject(res.data)
            }
        })
    })
}
function getBaiduToken(e){
    console.log(e);
    return new Promise((resolve, reject)=>{
        asx({
            url:'http://suixichanquan.com/ajax/getbiaduyuntoken',
            method:'post',
            headers:{'Content-Type':'application/json'}
        }).then(res=>{
            if(res.status==200){
                if(res.data.status == -404&&(url.includes('GetSmyUserInfo')||url.includes('SmyHouseListQuery'))){
                    goSmyUrl();
                }
                resolve(res.data)
            }else{
                reject(res.data)
            }
        })
    })
}
function recoganizeCard(data,params){
    
    return new Promise((resolve, reject)=>{
        asx({
            url:'https://aip.baidubce.com/rest/2.0/ocr/v1/idcard?access_token='+params,
            method:'post',
            headers:{'Content-Type':'application/x-www-form-urlencoded'},
            data:{
                image:data,
                id_card_side:'front',
                detect_quality: 'true',
                detect_photo: 'true',
                detect_direction: 'true'
            }
        }).then(res=>{
            if(res.status==200){
                if(res.data.status == -404&&(url.includes('GetSmyUserInfo')||url.includes('SmyHouseListQuery'))){
                    goSmyUrl();
                }
                resolve(res.data)
            }else{
                reject(res.data)
            }
        })
    })
}
const API={
    Post,
    Get,
    xcxPost,
    goSmyUrl,
    getBaiduToken,
    recoganizeCard,
    postByForm
}
export default API