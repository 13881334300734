<template>
  <div v-wechat-title="$route.meta.title">
    <router-view v-slot="{ Component }">
      <keep-alive :include="cacheList">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <a-modal v-model:open="followUp" style="width:80%" :footer="null" :closable="false" @ok="handleOk">
    <div class="followUpBox">
      <img src="@/static/icon/Group 1680@3x.png" alt="" class="followUpImg">
      <p class="followUpTitle">是否允许平台电话回访?</p>
      <p class="followContent">若您允许平台回访，平台工作人员将在工作时间通过电话联系您，为您提供全面的购房、卖房服务</p>
      <button class="allow1" @click="allowPost(false)">不允许</button>
      <button class="allow2" @click="allowPost(true)">允许</button>
    </div>
  </a-modal>
</template>

<script>

import { store } from "@/Tool/store.js";
import API from "@/Tool/Api.js";
import { message } from 'ant-design-vue';

export default {
  name: 'App',
  data() {
    return {
      cacheList: store.cachedViews,//缓存组件
      followUp: false,
      uid: 0,
      name: '',
      head_img: '',
      phone: ''
    }
  },
  components: {
  },
  mounted() {

    let that = this;
    // 
    let data = window.localStorage.getItem('qbSmyUserInfo')
    that.init();
    // if(data){
    //   //本地有信息就不再登录了
    //   return
    // }
    let str = window.location.search.replace('?', '')
    let strArr = str.split('&')

    strArr.forEach(item => {
      if (item.includes('uid')) {
        this.uid = item.split('=')[1]
      } else if (item.includes('name')) {
        this.name = item.split('=')[1]

      } else if (item.includes('head_img')) {
        this.head_img = item.split('=')[1]

      } else if (item.includes('phone')) {
        this.phone = item.split('=')[1]
      }
    })
    if (this.uid && this.name && this.head_img && this.phone) {
      store.temporaryData = {
        uid: this.uid,
        name: this.name,
        head_img: this.head_img,
        phone: this.phone
      }
    }else if(data){
      store.UserInfo = JSON.parse(data);
return
    }  else {
      message.error('没有登录信息')
      return
    }

    console.log(this);
    that.newLogin();
  

  },
  methods: {
    //登录
    newLogin() {
      API.Post('GetQbUserInfo', { qbUId: this.uid, nickName: this.name, phone: this.phone, headimg: this.head_img }).then(res => {
        if (res.status == 0) {
          message.success('登录成功')
          store.UserInfo = res.data;
          window.localStorage.setItem("qbSmyUserInfo", JSON.stringify(res.data))
        } else {
          message.success(res.message)

          // this.$router.replace('/login')
        }
      }).catch(err => {
        message.error('登录失败');

      })

    },
    //获取当前用户的状态(是否是新用户)
    getUsersStatus() {
      console.log('查询回访状态');
      API.Get('SmyGetLocationRegion/GetUserIsReturn', {
        name: store.UserInfo.name,
        phone: store.UserInfo.phone
        //用户信息
      }).then(res => {
        if (res.data == -1) {
          //当前用户满足新用户条件 触发弹窗
          setTimeout(() => {
            //间隔6秒弹窗
            this.followUp = true
          }, 6000);

        }

      }).catch(err => {

      })
    },
    //发送回访
    allowPost(e) {
      // this.followUp=false
      // console.log(store.UserInfo);
      // return
      API.Get('SmyGetLocationRegion/ResetIsReturn', {
        name: store.UserInfo.name,
        phone: store.UserInfo.phone,
        type: e ? 1 : 0
        //用户信息
        //是否回访e
      }).then(res => {
        this.followUp = false
      }).catch(err => {
        this.followUp = false
      })
    },
    Login(code) {
      // console.log(code)
      let that = this;
      if (that.GetList('SmyUserInfo') == undefined) {
        if (code == undefined) {
          // console.log(1)
          API.goSmyUrl();
          return;
        }
        // console.log(123)
        API.Get('GetSmyUserInfo', { code: code }).then(res => {
          console.log(res)
          if (res.status == 0) {
            store.UserInfo = res.data;
            that.SetList(res.data, 'SmyUserInfo');
            that.getUsersStatus()
          } else {
            message.error(res.message);
          }
        });
      } else {
        store.UserInfo = that.GetList('SmyUserInfo');
      }

    },
    init() {
      var webId = '1281330457';
      if (navigator.userAgent.toLowerCase().indexOf('tfsmy') > -1) { // 若在天府市民云app中
        eshimin.getDeviceId({
          onResponse: function (deviceInfo) {
            deviceInfo = JSON.parse(deviceInfo);
            console.log(deviceInfo)
            eshimin.getUserInfo({
              onResponse: function (userInfo) {
                userInfo = JSON.parse(userInfo);
                console.log()
                if (deviceInfo.code === '200') {
                  var deviceId = deviceInfo.deviceId;
                }
                if (userInfo.code === '200') {
                  var userName = userInfo.userName;
                }
                Trinity.init({
                  id: webId,
                  userName: userName,
                  deviceId: deviceId,
                  callback: function () {
                    Trinity.add_event({
                      "key": "pageview",
                      "segmentation": {
                        "action": "首页访问"
                      }
                    });
                  }
                });
              }
            })
          }
        })
      }
      else {
        Trinity.init({
          id: webId
        });
      }
    },
    SetList(data, name) {
      window.sessionStorage.setItem(name, JSON.stringify(data))
    },
    GetList(name) {
      if (window.sessionStorage.getItem(name) == undefined) {
        return undefined;
      }
      return JSON.parse(window.sessionStorage.getItem(name));
    },
  }
}
</script>

<style>
body {
  background-color: #F8F8F8;
  margin: 0;
}

input {
  border: none;
  background: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

.isNull {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.isNull img {
  width: 300px;
  height: 215px;
}

.isNull .text {
  font-size: 18px;
  color: #999999;
  margin-top: 8px;
}

.ant-input {
  border: none;
}

.ant-input:focus {
  border: none;
  box-shadow: none;
  color: #666666;
}

.ant-spin {
  max-height: none !important;
}

.ant-badge {
  width: auto !important;
}

.followUpBox {
  width: 100%;
  text-align: center;
}

.followUpBox .followUpImg {
  /* width: 50px; */
  height: 80px;
  margin: 0 auto;
}

.followUpBox .followUpTitle {
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
}

.followUpBox .followContent {
  margin: 0 auto;
  width: 90%;
  font-size: 13px;
}

.followUpBox button {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  margin-top: 20px;
}

.followUpBox .allow1 {
  margin-right: 5px;
  background-color: #FFFFFF;
  color: #A45C24;
  border: 1px solid #A45C24;
}

.followUpBox .allow2 {
  margin-left: 5px;
  background-color: #DDB779;
  color: #A45C24;
  border: 1px solid #EFD19F;
}
</style>
